<template>
<div id="app">
  <el-container class="mw-layout">

    <el-header style="height: auto;">
      <img src="./assets/ottez-logo-negative.svg" class="logo" />
      <div class="links">
        <a :href="discordUrl"><i class="fab fa-discord"></i></a>
        <a :href="twitterUrl"><i class="fab fa-twitter"></i></a>
        <el-button plain v-if="checkWalletConnected() && wallet.connected === false" @click="connectWallet">Sync</el-button>
        <el-button plain v-else @click="disconnectWallet">Unsync</el-button>
        <span v-if="wallet.connected" class="hidden-xs-only" style="margin-left: 15px;">{{ `${wallet.pkh.substr(0, 6)}...${wallet.pkh.substr(-6)}` }}</span>
      </div>
    </el-header>

    <el-main class="main">

      <el-row>
        <el-col :sm="24" :md="12">
          <h1 style="margin-top: 0;">Humans are gone.<br/><span>Ottez have moved in.</span></h1>
          <p class="lg">After surviving an apocalyptic event, 4,007 generative, collectible PFP otters have taken over Earth and are available as NFTs on the Tezos blockchain.</p>
        </el-col>
        <el-col :sm="24" :md="12" style="text-align: center;">
          <img src="./assets/ottez-intro-1.png" style="max-width: 100%" />
        </el-col>
      </el-row>

      <div class="mint" style="margin-top: 75px;" v-loading="mintOpPending" element-loading-background="rgba(0, 0, 0, 0.65)">
        <el-row>
          <el-col v-if="isMinting && numMinted >= maxMint" :xs="24" :md="12">
            <h1 style="margin-top: 0;">Ottez are<br /><span>SOLD OUT!</span></h1>
            <p><strong>Thank you to everyone who bought Ottez.</strong></p>
          </el-col>
          <el-col v-else :xs="24" :md="12">
            <h1 style="margin-top: 0;">Mint Ottez</h1>
            <p><strong>Pre-sale Mint Price: 6.5XTZ</strong><br />
                <small>Available to OtterList members on 27th Jan 2022 at 15:00 GMT</small></p>
            <p><strong>Public Sale Mint Price: 8.5XTZ</strong><br />
                <small>Available to the public on 28th Jan 2022 at 15:00 GMT</small></p>
          </el-col>
          <el-col v-if="isMinting && numMinted >= maxMint" :xs="24" :md="12" style="position: relative; text-align: center; margin-top: 0px;">
            <el-button type="info" @click="gotoObjkt" style="width: 90%; margin: 15px 0;">View Collection on Objkt<i class="far fa-external-link" style="margin-left: 8px;"></i></el-button>
            <el-button type="info" @click="gotoRarible" style="width: 90%; margin: 15px 0;">View Collection on Rarible<i class="far fa-external-link" style="margin-left: 8px;"></i></el-button>
            <el-button type="info" @click="gotoGallery" style="width: 90%; margin: 15px 0;">View Rarity Gallery<i class="far fa-external-link" style="margin-left: 8px;"></i></el-button>
          </el-col>
          <el-col v-else :xs="24" :md="12" style="position: relative; text-align: center; margin-top: 25px;">
            <el-progress :show-text="false" :stroke-width="40" :percentage="parseInt((numMinted / maxMint) * 100)" style="margin-bottom: 25px;"></el-progress>
            <span v-if="!isMinting" class="sale-status">Minting soon!</span>
            <span v-else-if="isWhitelist" class="sale-status">Pre-sale in progress</span>
            <span v-else-if="isMinting && numMinted < maxMint" class="sale-status">Public sale in progress</span>
            <span v-else class="sale-status">Sold out</span>
            <span v-if="isMinting" class="sold-counter">{{ numMinted }}/{{ maxMint }} sold</span>
            <el-row v-if="!isMinting || isWhitelist" :gutter="30" style="text-align: center;">
              <el-col :xs="24" :md="12" style="padding: 15px;">
                <el-button type="info" :disabled="!isMinting" @click="mint(1)">MINT 1</el-button>
              </el-col>
              <el-col :xs="24" :md="12" style="padding: 15px;">
                <el-button type="info" :disabled="!isMinting" @click="mint(2)">MINT 2</el-button>
              </el-col>
            </el-row>
            <el-row v-else :gutter="30" style="text-align: center;">
              <el-col :xs="24" :md="12" style="padding: 15px;">
                <el-button type="info" :disabled="numMinted >= maxMint" @click="mint(1)">MINT 1</el-button>
              </el-col>
              <el-col :xs="24" :md="12" style="padding: 15px;">
                <el-button type="info" :disabled="numMinted >= maxMint" @click="mint(3)">MINT 3</el-button>
              </el-col>
            </el-row>
            <el-row v-if="!isMinting || isWhitelist" :gutter="30" style="text-align: center;">
              <el-col :xs="24" :md="12" style="padding: 15px;">
                <el-button type="info" :disabled="!isMinting" @click="mint(3)">MINT 3</el-button>
              </el-col>
              <el-col :xs="24" :md="12" style="padding: 15px;">
                <el-button type="info" :disabled="!isMinting" @click="mint(4)">MINT 4</el-button>
              </el-col>
            </el-row>
            <el-row v-else :gutter="30" style="text-align: center;">
              <el-col :xs="24" :md="12" style="padding: 15px;">
                <el-button type="info" :disabled="numMinted >= maxMint" @click="mint(5)">MINT 5</el-button>
              </el-col>
              <el-col :xs="24" :md="12" style="padding: 15px;">
                <el-button type="info" :disabled="numMinted >= maxMint" @click="mint(10)">MINT 10</el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>

      <el-row style="margin-top: 75px">
        <el-col :sm="24" :md="12" style="text-align: center;">
          <img src="./assets/ottez-traits-1.png" style="max-width: 100%" />
        </el-col>
        <el-col :sm="24" :md="12">
          <h1>Ottez <span>traits</span></h1>
          <p style="font-size: 24px;">Otterly Individual.</p>
          <p>Ottez can have up to eight different variable traits (Eyewear, Headgear, Piercings, Clothing, Eyes, Mouth, Tail, Fur) + a background. The traits contain a rarity system, which allow the Ottez to be ranked in terms of rarity. We will be releasing a rarity-checker soon.</p>
          <p>The Magnificent 7, of course, are unique and do not follow this system. They are the rarest Ottez. </p>
        </el-col>
      </el-row>

      <div class="benefits" style="margin-top: 75px; text-align: center;">
        <h1>Why <span>buy Ottez?</span></h1>
        <el-row style="margin-top: 75px" :gutter="20">
          <el-col :sm="12" :md="6" style="text-align: center;">
            <p><span><i class="far fa-check-circle"></i><br />Awesome art</span>
              Clean, original, illustrated characters created by an actual artist.</p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center;">
            <p><span><i class="far fa-check-circle"></i><br />Great community</span>
              Join the Ottez Den on Discord for daily discussions.</p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center;">
            <p><span><i class="far fa-check-circle"></i><br />Dope PFP</span>
              Represent your personality with an Ottez that is unique to you.</p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center;">
            <p><span><i class="far fa-check-circle"></i><br />Clean NFT</span>
              Tezos is the environmentally-friendly blockchain.</p>
          </el-col>
        </el-row>
        <el-row style="margin-top: 35px" :gutter="20">
          <el-col :sm="12" :md="6" style="text-align: center;">
            <p><span><i class="far fa-check-circle"></i><br />No gas</span>
              Spend your crypto on the NFT, rather than the gas fee</p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center;">
            <p><span><i class="far fa-check-circle"></i><br />Community fund</span>
              We have a community fund to help ensure the success of the project</p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center;">
            <p><span><i class="far fa-check-circle"></i><br />Artist Platform</span>
              We’re building a community to help showcase artists on Tezos</p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center;">
            <p><span><i class="far fa-check-circle"></i><br />Future drops</span>
              We hope to do airdrops, giveaways, collabs and more.</p>
          </el-col>
        </el-row>
      </div>

      <el-row style="margin-top: 125px; margin-bottom: 50px;">
        <el-col :sm="24" :md="12" style="text-align: center;">
          <img src="./assets/ottez-story-1.png" style="max-width: 100%" />
        </el-col>
        <el-col :sm="24" :md="12">
          <h1>The story <span>so far...</span></h1>
          <p>The world's nuclear power plants were convinced to allow their operations to be coordinated on Cordona Blockchain Smart Contracts after furious marketing from their leader Bob Hoskins (not the actor). The world's nuclear energy supply became a ticking time bomb and went into meltdown.<p>
          <p>Radiation began to cover the planet wiping out all trace of human life. One canny species had a little-known advantage. Otters retreated to their dens and hunkered down, but the radiation seeped in as they slept and began to transform them.</p>
          <p>When the first seven Ottez began emerging from their dens, they were the most advanced... The Magnificent 7. They quickly began adopting the traits and styles of their fallen human counterparts. Wearing their clothes, enjoying their leisure activities. As more otters awoke, new styles emerged.</p>
          <p><a :href="discordUrl">Read the full lore on our Discord</a></p> 
        </el-col>
      </el-row>

    </el-main>
  </el-container>

  <el-container style="background: #fff; border-top: 10px solid #6a5e9e; border-bottom: 10px solid #6a5e9e">
    <div class="mw-layout mag-7">
      <div style="margin-top: 75px; text-align: center; margin-bottom: 75px;">
        <h1>Introducing... <span>The Magnificent 7</span></h1>
        <p style="font-size: 24px;">7 unique, 1 of 1 Ottez. The rarest of creatures.</p>
        <div class="avatar-row">
          <div class="avatar">
            <el-avatar shape="square" :size="250" :src="avatar1"></el-avatar>
            <span class="title">Shimada Knife-tail</span>
          </div>
          <div class="avatar">
            <el-avatar shape="square" :size="250" :src="avatar2"></el-avatar>
            <span class="title">The Golden Otter</span>
          </div>
          <div class="avatar">
            <el-avatar shape="square" :size="250" :src="avatar3"></el-avatar>
            <span class="title">Valentina</span>
          </div>
          <div class="avatar">
            <el-avatar shape="square" :size="250" :src="avatar4"></el-avatar>
            <span class="title">The Crypt Keeper</span>
          </div>
        </div>
        <div class="avatar-row">
          <div class="avatar">
            <el-avatar shape="square" :size="250" :src="avatar5"></el-avatar>
            <span class="title">Robo-Otter</span>
          </div>
          <div class="avatar">
            <el-avatar shape="square" :size="250" :src="avatar6"></el-avatar>
            <span class="title">Terminus</span>
          </div>
          <div class="avatar">
            <el-avatar shape="square" :size="250" :src="avatar7"></el-avatar>
            <span class="title">The Tez Ninja</span>
          </div>
        </div>
      </div>
    </div>
  </el-container>

  <el-container class="mw-layout">
    <el-main>
      <div class="team" style="margin-top: 75px; text-align: center;">
        <h1>The <span>Ottez Team</span></h1>
        <el-row style="margin-top: 50px" :gutter="20">
          <el-col :sm="12" :md="6" style="text-align: center; padding-top: 25px;">
            <el-avatar shape="circle" :size="250" :src="teamAvatar1"></el-avatar>
            <p><span>Wwwombats</span>Artist & Co-Founder
                <br/><a href="https://twitter.com/wwwombats" target="_blank">@wwwombats</a></p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center; padding-top: 25px;">
            <el-avatar shape="circle" :size="250" :src="teamAvatar2"></el-avatar>
            <p><span>Paggy Almeyda</span>Co-Founder
                <br/><a href="https://twitter.com/paggyalmeyda" target="_blank">@paggyalmeyda</a></p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center; padding-top: 25px;">
            <el-avatar shape="circle" :size="250" :src="teamAvatar3"></el-avatar>
            <p><span>iMompy</span>Discord Mod
                <br/><a href="https://twitter.com/imompy" target="_blank">@imompy</a></p>
          </el-col>
          <el-col :sm="12" :md="6" style="text-align: center; padding-top: 25px;">
            <el-avatar shape="circle" :size="250" :src="teamAvatar4"></el-avatar>
            <p><span>CodeCrafting</span>Dev
                <br/><a href="https://twitter.com/mycodecrafting" target="_blank">@mycodecrafting</a></p>
          </el-col>
        </el-row>
      </div>

      <div class="faq" style="margin-top: 75px;">
        <h1 style="text-align: center;">The <span>FAQs</span></h1>
        <div class="faq-item">
          <h2>1. How many Ottez will be minted?</h2>
          <p>There will be 4007 Ottez minted during the sale.</p>
        </div>
        <div class="faq-item">
          <h2>2. Will there be rarity attached to each Ottez</h2>
          <p>Yes, there will be a rarity scale attached similar to other generative NFT projects. Additionally, there will be 7 Unique Ottez (The Magnificent 7) who share no traits with the other Ottez.</p>
        </div>
        <div class="faq-item">
          <h2>3. When is the launch date?</h2>
          <p>The launch date has been announced as the 27th January 2022 at 15:00 GMT for Pre Sale and 28th January 2022 at 15:00 GMT for the Public Sale.</p>
        </div>
        <div class="faq-item">
          <h2>4. Can I get Whitelisted?</h2>
          <p>Yes, but we call it the OtterList. If you make it to the Ottez Elite then you are automatically on the OtterList. We will award this for those who are active with invites, chatting and helping the community. There will also be giveaways and collabs to attain a place on the OtterList.</p>
        </div>
        <div class="faq-item">
          <h2>5. Where can I buy Ottez?</h2>
          <p>Minting will take place here on our official site. After the sale they will be listed on Objkt, Rarible and ByteBlock.</p>
        </div>
        <div class="faq-item">
          <h2>6. How much will each Ottez cost to mint?</h2>
          <p>We have devised a pricing structure which rewards our early adopters. Those on the OtterList will get a chance to mint each Ottez at 6.5XTZ in the pre-sale. Once the pre-sale is over the price of each mint will increase to 8.5XTZ. You will be able to buy a maximum of 10 Ottez per wallet.</p>
        </div>
        <div class="faq-item">
          <h2>7. How will you ensure fair distribution?</h2>
          <p>The contract includes a provenance hash. This is a SHA-256 hash of an IPFS directory that refers to a fingerprint of the tokens and the art. It means anyone can verify that we didn’t change the tokens after minting them. Also, when the sale starts, the id of each mint is randomized.</p>
        </div>
        <div class="faq-item">
          <h2>Provenance &amp; Contracts</h2>
          <p v-if="crowdSaleContract">Crowdsale Contract: <a :href="`https://better-call.dev/mainnet/${crowdSaleContract}/operations`">{{ crowdSaleContract }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
          <p v-else>Crowdsale Contract: <strong>Coming Soon!</strong></p>
          <p v-if="fa2Contract">Ottez NFT Contract: <a :href="`https://better-call.dev/mainnet/${fa2Contract}/operations`">{{ fa2Contract }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
          <p v-else>Ottez NFT Contract: <strong>Coming Soon!</strong></p>
          <p v-if="provenanceHash">Provenance Hash:<br/><strong>{{ provenanceHash }}</strong></p>
          <p v-else>Provenance Hash: <strong>Coming Soon!</strong></p>
        </div>
      </div>

    </el-main>
    <el-footer style="height: auto;">
      <img src="./assets/ottez-logo-negative.svg" class="logo" />
      <div class="links">
        <a :href="discordUrl" target="_blank"><i class="fab fa-discord"></i></a>
        <a :href="twitterUrl" target="_blank"><i class="fab fa-twitter"></i></a>
        <a href="https://www.instagram.com/otteznft/" target="_blank"><i class="fab fa-instagram"></i></a>
      </div>
    </el-footer>
  </el-container>

</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getWalletContract } from './utils/tezos';
import tzkt from './utils/tzkt';

const avatar1 = require('./assets/ottez-mag7-shimadaknifetail.png');
const avatar2 = require('./assets/ottez-mag7-thegoldenotter.png');
const avatar3 = require('./assets/ottez-mag7-valentina.png');
const avatar4 = require('./assets/ottez-mag7-thecryptkeeper.png');
const avatar5 = require('./assets/ottez-mag7-robootter.png');
const avatar6 = require('./assets/ottez-mag7-terminus.png');
const avatar7 = require('./assets/ottez-mag7-thetezninja.png');

const teamAvatar1 = require('./assets/ottez-team-wwwombats.png');
const teamAvatar2 = require('./assets/ottez-team-paggyalmeyda.png');
const teamAvatar3 = require('./assets/ottez-team-imompy.png');
const teamAvatar4 = require('./assets/ottez-team-codecrafting.png');

export default {
  name: 'app',
  data() {
    return {
      avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7,
      teamAvatar1, teamAvatar2, teamAvatar3, teamAvatar4,
      discordUrl: "https://discord.gg/PN9mCTb8",
      twitterUrl: "https://twitter.com/otteznft",
      crowdSaleContract: process.env.VUE_APP_CROWDSALE_CONTRACT,
      fa2Contract: process.env.VUE_APP_FA2_CONTRACT,
      provenanceHash: process.env.VUE_APP_PROVENANCE_HASH,
      whitelistStartTime: new Date('2022-01-27T15:00:00Z'),
      whitelistEndTime: new Date('2022-01-28T15:00:00Z'),
      saleStartTime: new Date('2022-01-28T15:00:00Z'),
      numMinted: 0,
      maxMint: 4007,
      mintOpPending: false,
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ]),

    isWhitelist: function() {
      const now = new Date();
      return (now >= this.whitelistStartTime && now < this.whitelistEndTime);
    },

    isMinting: function() {
      return this.isWhitelist || (new Date() >= this.saleStartTime);
    }
  },
  created() {
    this.updateMinted();
  },
  methods: {
    ...mapActions([
      'checkWalletConnected',
      'connectWallet',
      'disconnectWallet',
      'changeWallet'
    ]),

    gotoObjkt() {
      window.open('https://objkt.com/collection/ottez');
    },

    gotoRarible() {
      window.open('https://rarible.com/collection/tezos/' + this.fa2Contract);
    },

    gotoGallery() {
      window.open('https://ottez.gallery/');
    },

    formatProgress() {
      return '';
    },

    async updateMinted() {
      const storage = await tzkt.getContractStorage(this.crowdSaleContract);
      this.numMinted = parseInt(storage.data.sale.soldAmount);
      if (this.numMinted < this.maxMint) {
        const vm = this;
        setTimeout(vm.updateMinted, 15000);
      }
    },

    async mint(mintNum) {
      const amount = parseInt(mintNum * (this.isWhitelist ? 6500000 : 8500000));
      if (mintNum < 1 || amount < 1) {
          return;
      }

      try {
        await this.connectWallet();
        const op = await (await getWalletContract(process.env.VUE_APP_CROWDSALE_CONTRACT))
          .methods
          .mint(mintNum)
          .send({
            amount: amount,
            mutez: true,
            // fee: 55000,
            // gasLimit: 48000,
            // storageLimit: 1000
          });
        this.mintOpPending = true;
        await op.confirmation();
      } catch (e) {
        console.log(e);
      }

      this.mintOpPending = false;
    }
  }
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
  height: 100%;
  background: #000 url('assets/ottez-hero-1.png') no-repeat center 75px;
}

body {
  font-family: 'DM Sans', sans-serif;
  color: #fff;
}


.mw-layout {
  max-width: 1280px;
  margin: 0 auto;
}


.el-main {
  padding-left: 20px !important;
  padding-right: 20px !important;
  &.main {
    margin-top: 575px;
  }
}

#app .el-button.is-plain {
  background: transparent;
  border: 2px solid #dc7551;
  border-color: #dc7551;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  border-radius: 5px;
}
#app .el-button.is-plain:hover {
  border-color: #6a5e9e;
  color: #fff;
}

#app .el-select {
  width: 80px;
  margin-right: 15px;
}

#app .el-input__inner {
  background: #000;
  color: #fff;
}

.el-header, .el-footer {
  position: relative;
}

.el-footer {
    margin: 100px 0 !important;
}

.logo {
  width: 95px;
  margin-top: 15px;
}

.links {
  position: absolute;
  top: 18px;
  right: 20px;
}

.links a,
.links a:visited {
  display: block;
  margin-top: 6px;
  float: left;
  font-size: 28px;
  color: #fff;
  margin-right: 24px;
  line-height: 32px;
}
.links a:hover {
  color: #6a5e9e;
}


h1 {
  color: #fff;
  font-family: 'Rancho', cursive;
  font-size: 64px;
  margin-bottom: 32px;
  line-height: 66px;

  span {
    color: #d19f49;
  }
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #9F9F9F;
  margin-bottom: 24px;

  &.lg {
    font-size: 24px;
    line-height: 32px;
  }

  a {
    color: #dc7551;
    text-decoration: none;
    &:hover {
      color: #6a5e9e;
      text-decoration: underline;
    }
  }
}

.mint {
  background: #dc7551;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 0px 15px 15px rgba(220, 117, 81, 0.20);
  h1 {
    span {
      color: #000;
    }
  }
  p {
    color: #292b2d;
    strong {
      color: #000;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .el-button {
    &.el-button--info {
      padding: 15px 75px;
      font-size: 18px;
      letter-spacing: 2px;
      &:hover, &:focus, &:active {
        border-color: #6a5e9e;
        background-color: #6a5e9e;
      }
      &.is-disabled {
        color: #d89680;
        border-color: #944337;
        background-color: #944337;
      }
    }
  }
  .el-progress-bar__outer {
    background: #fff;
    border: 2px solid #fff;
    .el-progress-bar__inner {
      background-color: #5b7354;
    }
  }
  .sold-counter {
    position: absolute;
    top: 12px;
    right: 20px;
    color: #000;
  }
  .sale-status {
    position: absolute;
    top: 12px;
    left: 20px;
    color: #000;
    font-weight: bold;
  }
  .el-loading-mask {
    border-radius: 8px;
  }
}

.benefits,
.team {
  p {
    span {
      font-size: 26px;
      color: #fff;
      display: block;
      margin-bottom: 6px;

      i {
        font-size: 32px;
        margin-bottom: 8px;
      }
    }
  }
}

.mag-7 {
  color: #292b2d;
  flex: 1;
  flex-basis: auto;

  h1 {
    color: #292b2d;
  }
  p {
    color: #292b2d;
  }

  .avatar-row {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;

    .avatar {
      display: flex;
      flex-direction: column;
      flex: 0 1 0%;
      margin: 0 25px;
      margin-top: 50px;
      .title {
        margin-top: 12px;
      }
    }
  }

}

.faq {
    margin: 0 150px;
    color: #292B2D;
    .faq-item {
        padding: 15px 0;
        border-bottom: 1px solid #292B2D;

        h2 {
            color: #fff;
            font-size: 24px;
            margin-bottom: 12px;
        }
        p {
            color: #9F9F9F;
        }
    }
}

.el-avatar {
    background: transparent;
}

@media only screen and (max-width: 991px) {
  body {
    background-size: 200% auto;
  }
  .el-main {
    &.main {
      margin-top: 56%;
    }
  }
  .faq {
    margin: 150px 0px;
    margin-bottom: 0;
  }
  .el-footer {
      margin: 20px 0 !important;
  }
}

</style>
